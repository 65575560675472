import React from 'react'
import { FormattedMessage } from 'react-intl'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Carousel from '../components/carousel'
import coffee from '../images/hero-news.jpg'

const NewsPage = (props) => (
  <Layout>
    <SEO title="News" />
    <Carousel
      title={<FormattedMessage id="navbar-3" />}
      subtitle={<FormattedMessage id="news--subtitle" />}
      size={'is-medium'}
      // color={"is-primary"}
      img={coffee}
      imgalt="news"
    />
    <div className="main">
      <div className="card">
        <div className="card-content is-flex-mobile">
          <div className="columns">
            <div className="column is-one-third">
              <figure className="image">
                <GatsbyImage
                  image={props.data.browser.childImageSharp.gatsbyImageData}
                  alt="browser"
                />
              </figure>
            </div>
            <div className="column">
              <h1 className="title">
                <FormattedMessage id="news--card1-heading" />
                <span role="img" aria-label="Grinning Face With Big Eyes">
                  😃
                </span>
              </h1>
              <div className="content">
                <FormattedMessage id="news--card1-content" />
              </div>
              <span className="tag is-info">
                {' '}
                <FormattedMessage id="news--card1-tag" />
              </span>
              <time dateTime="2019-8-20" style={{ float: `right` }}>
                20 Aug 2019
              </time>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export const aboutImage = graphql`
  fragment aboutImage on File {
    childImageSharp {
      gatsbyImageData(width: 400, layout: CONSTRAINED)
    }
  }
`

export const query = graphql`
  query {
    coffee: file(relativePath: { eq: "hero-news.jpg" }) {
      ...aboutImage
    }
    browser: file(relativePath: { eq: "news-1.jpg" }) {
      ...aboutImage
    }
  }
`

export default NewsPage
